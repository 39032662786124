<template>
  <div class="list">
    <Loading :value="loading" />
    <div v-if="!!$slots.filter" class="list-filter">
      <div class="list-filter-inner secondary pt-2 pb-2 pl-4 pr-1">
        <div v-if="selectable">
          <v-checkbox
            @click="selectAll"
            :value="isAllSelected()"
            hide-details
          />
        </div>
        <slot name="filter"/>
      </div>
    </div>

    <div class="list-content">
      <v-list class="pa-0" two-line>
        <template v-for="(item, i) in items">
          <v-list-item 
            link
            :key="i"
            :class="[isSelected(item) ? 'selected blue lighten-5' : '']"
            @click="goToItem(item)"
          >
            <v-list-item-action v-if="selectable">
              <v-checkbox
                @click.stop="select(item)"
                :value="isSelected(item)"
                hide-details
              />
            </v-list-item-action>

            <slot name="row" v-bind="item"/>

          </v-list-item>
          <v-divider :key="'div'+i" />
        </template>
      </v-list>
    </div>

    <div v-if="$slots.footer" class="list-footer">
      <div class="list-footer">
        <slot name="footer"/>
      </div>
    </div>
  </div>
</template>

<style>
.list {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

/* .list .v-list-item__title {
} */

.list-content {
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.list-content .v-list-item {
  background: none;
  transition: none;
}
.list-content .v-list-item:hover {
  background: rgba(0,0,0,.12)
}

.list-filter {
  width: 100%;
  z-index: 1;
  box-shadow: 0 0 8px 3px rgba(0,0,0,.5);
}
.list-filter-inner {
  display: flex;
  border-bottom: solid 1px #d0d0d0;
}

.list-filter-inner > div {
  padding: 0 6px;
}
.list-filter-inner > div:first-child {
  padding-left: 0;
}
.list-filter-inner > div:last-child {
  padding-right: 0;
}
.list-filter .v-btn-toggle {
  margin-top: 6px;
  height: 30px;
  border: solid 1px rgba(0, 0, 0, 0.38);
  text-transform: uppercase;
  font-weight: bold;
}
.list-filter .v-input:not(.v-input--checkbox) .v-input__slot {
  background: #fff !important;
}

.list-filter .v-input--selection-controls {
  margin-top: 5px;
}

</style>

<script>
import Loading from '@/components/Loading'

export default {
  name: 'List',
  data: () => ({
    selectedItems: []
  }),
  components: {
    Loading
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    },
    selectable: {
      type: Boolean,
      default: true
    },
    itemValue: {
      type: String,
      default: 'id'
    },
    selected: {
      type: Array,
      default: () => []
    }
  },

  watch: {
    selected (n) {
      this.selectedItems = n.map(v => v[this.itemValue] || v)
    }
  },

  created () {
    this.$emit('select', this.selectedItems)
  },

  methods: {

    selectAll () {
      if (this.selectedItems.length === this.items.length) {
        this.selectedItems = []
      } else {
        const mapItemValue = item => item[this.itemValue]
        this.selectedItems = this.items.map(mapItemValue)
      }
      this.$emit('select', this.selectedItems)
    },

    select (item) {
      const value = item[this.itemValue]
      const index = this.selectedItems.indexOf(value)
      if (index === -1) {
        this.selectedItems.push(value)
      } else {
        this.selectedItems.splice(index, 1)
      }
      this.$emit('select', this.selectedItems)
    },

    goToItem (item) {
      this.$emit('click', item)
    },


    isAllSelected () {
      return this.selectedItems.length === this.items.length && this.selectedItems.length !== 0
    },

    isSelected (item) {
      const value = item[this.itemValue]
      return this.selectedItems.indexOf(value) !== -1
    }
  }
}
</script>
